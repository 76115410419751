import loadImage from "blueimp-load-image";

document
  .querySelector("input[type=file]")
  .addEventListener("change", async function () {
    const resizedImage = await loadImage(this.files[0], {
      // resize before sending to PhotoRoom for performance
      maxWidth: 5000,
      maxHeight: 5000,
      canvas: true,
    });

    resizedImage.image.toBlob(async function (inputBlob) {
      const formData = new FormData();
      formData.append("image_file", inputBlob);

      const response = await fetch("https://sdk.photoroom.com/v1/segment", {
        method: "POST",
        headers: {
          "x-api-key": "e1f0438b08b2f0f0d94f1f66641ffaccd680d563",
          // arnonjin
          // "x-api-key": "943864739bdca32e2254889076a4827fa6640d67",
          // arnonjin
          // "x-api-key": "0a518f8604b9bc0a9035a0b3e0a96a0def8e2821",
          // arnonvector
          // "x-api-key": "d52275c31a541c6622846deb029a5675d26de7da",
          // arnonprogrammer
          // "x-api-key": "b4f129253fe77edda42bc18c23e8427fea0165c0",
          // arnonmusicmovia
          //"x-api-key": "48f57db29c1eb7cb2db61ac1315920e6cbb6ebcd",
          // arnonwebmaster
          // "x-api-key": "bf22628f930f8a630f60ce0802b2be1613acf99b",
          // arnonidol
          // "x-api-key": "59c261c7735d69cc1e09c61e784d7233e0a66104",
          // arnongamemo
          // "x-api-key": "83616cef4a0094de201a69f3a74009005a18c649",
          // arnonnovel
          // "x-api-key": "856316c7ea4bfd29e05937d8bc08168e85c6c89e",
          // arnonphotomo
          // "x-api-key": "1d269a3df80074b7511bd69cc7e858fcf769c94e",
          // arnoninfographic
          // "x-api-key": "b679b687cdbd106e2b2cd7af1df565f41c77da94",
          // arnongraphic
          // "x-api-key": "2d49405d55989952839c7c1da1e04a3a8562b3ec",
          // arnonjindatongtawee
          // "x-api-key": "71cc9d3f7af6e35345e1709054e4bf3de66958a2",
          // arnonjin8
          // "x-api-key": "03e773a7bdb6739dc4275d1a7154c5b224f497eb",
          // arnonjin6
          // "x-api-key": "5fe0f570a284d940036bc62d44e313d95852848d",
          // arnonsindy
          // "x-api-key": "99741d2568345cf89c16a231a22ee2cbdfd7401e",
          // arnonmarine22
          // "x-api-key": "62bb602992bf24a21c38922a8cc9c841fccf1800",
          // berryarnon
          // "x-api-key": "7d166d665424fd15ae9abd5c5515df50885cbed1",
          // arnonwawa48
          // "x-api-key": "c4e16538360c5f4863499d06e56743813a5dd0cd",
          // arnonjin5
          // "x-api-key": "d8596c6e93c269c9270b9e6311531bd1d02842a1",
          // arnonjanry
          // "x-api-key": "761fa84fde3bd66072eafc48f65ebbfb7b6cb655",
          // arnonpalmmy
          // "x-api-key": "2f65364d563e0d142fbf77d6544ad0b321ce8be0",
          // arnonlbnk48
          // "x-api-key": "d51865b64b00d09bc93fcc7b41438baab3dff6cb",
          // arnonemmy
          // "x-api-key": "f4d8dc578ccec08b42ea7e552eb34fc92a2cdde8",
          // arnonnene48
          // "x-api-key": "d2c241b6d72b9f3333caad41605d55a95d39f147",
          // pattarnon48
          // "x-api-key": "817b9eb3decd7487c673663abc4259afcf8babbe",
          // arnonmicha48
          // "x-api-key": "2ff2c2480a0290b4fecfdb57694dc30e4dff95ff",
          // arnonlookgade
          // "x-api-key": "32a8ef86172fc2f35c7f3d137f5c6caeb5dabd7f",
          // arnonjingjing
          // "x-api-key": "4a3e579fe945dfdd3e6c9b964e4e800afbb47c6f",
          // arnonnana48
          // "x-api-key": "09a4ee4fb1c10f09b9d59e656c011f68de269731",
          // arnonemma
          // "x-api-key": "20266c705d408d3b56262d4e3616c776d6c19a20",
          // arnonginna
          // "x-api-key": "0999b9adf2dfda95a5cbb5755c593f8a2c40f21a",
          // arnontwobam
          // "x-api-key": "9cc77432b6ec42f87537bb81ac7d2024b089229e",
          // arnonjin7
          // "x-api-key": "0ac46891cbce8029bbc6d1b35fc41c93b7227bdd",
          // arnonjin1
          // "x-api-key": "b36ed8a2024aaf0e3c66964a349d3fca9f57cf4b",
          // arnonjin2
          // "x-api-key": "4ee635a07fa969fe540a151a227b80d7c578ce13",
          // arnonjin3
          // "x-api-key": "768786e3273ac4d0b86d0f60ccb630a98794b45e",
          // arnonjin4
          // "x-api-key": "d75827f908367f3433b35f4279302b7b2d5847eb",
          // arnonpapang
          // "x-api-key": "",
          //
          // "x-api-key": "",
          //
          // "x-api-key": "",
          //
          // "x-api-key": "",
          //
          // "x-api-key": "",
          //
          // "x-api-key": "",
          //
        },
        body: formData,
      });

      // https://developers.google.com/web/ilt/pwa/working-with-the-fetch-api#example_fetching_images
      const outputBlob = await response.blob();
      const outputImage = document.createElement("img");
      outputImage.style.width = "100%";
      outputImage.src = URL.createObjectURL(outputBlob);

      // Create a link for download
      const downloadLink = document.createElement("a");
      downloadLink.href = outputImage.src;
      downloadLink.download = "downloaded_image.png"; // Provide the name of the file to be downloaded
      downloadLink.innerHTML = "(Download)";

      // Create a div to hold the image and the link
      const div = document.createElement("div");
      window.addEventListener("resize", function () {
        if (window.innerWidth <= 600) {
          div.style.width = "100%";
        } else if (window.innerWidth <= 900) {
          div.style.width = "18%";
        } else {
          div.style.width = "9%";
        }
      });

      // Initial setup
      if (window.innerWidth <= 600) {
        div.style.width = "100%";
      } else if (window.innerWidth <= 900) {
        div.style.width = "18%";
      } else {
        div.style.width = "9%";
      }

      div.style.display = "flex";
      div.style.justifyContent = "center";
      div.style.alignItems = "center";
      div.style.flexDirection = "column";
      div.style.padding = "10px";
      div.style.margin = "0.1%";

      // Append the image and the link to the div
      div.appendChild(outputImage);
      div.appendChild(downloadLink);

      // Append the div to the DOM
      document.querySelector("#result").appendChild(div);
    });
  });
